let localizations_sv = {
  content: {
    title: {
      title: 'Responsenkäten för yrkeshögskolestuderande i slutskedet av studierna (AVOP)'
    },
    kysely: {
      information: 'Enkäten i korthet',
      about1: 'AVOP responsenkäten är ett utvärderingssystem som ger studenten möjlighet att utvärdera och ge respons på sin utbildning. Utvärderingsresultaten används lokalt vid högskolorna med syfte att förbättra de egna processerna och att prägla uppföljningen och finansieringen av den nationella utbildningen. Alla utexaminerade yrkesehögskolestuderanden ombeds fylla i enkäten.',
      about2: 'Med hjälp av HAKA-identifieringen hämtas personens uppgifter gällande studierätten från VIRTA-datalager som bakgrundsinformation för att kunna skapa AVOP svarskoden och möjliggöra givande av respons elektroniskt. Uppgifter som skulle identifiera personen skickas inte vidare till AVOP-enkäten utan förfrågan är helt anonym.',
      about3: 'Den elektroniska enkäten AVOP, utgör en del av den nationella ARVO-tjänsten (informationstjänst för hantering av utbildningsärenden), som gör det möjligt att på nationell nivå samla ihop förenlig data om effekterna av utbildning.',
      privacy: {
        text: 'Utförligare information i <1>dataskyddsbeskrivning</1> och <3>tillgänglighetsutlåtande</3>.',
        privacyLink: 'https://wiki.eduuni.fi/pages/viewpage.action?pageId=147669165',
        accessibilityLink: 'https://wiki.eduuni.fi/pages/viewpage.action?pageId=303291918',
      }
    },
    login: {
      header: 'Yrkeshögskolestuderande',
      description: 'Använd din personliga HAKA-kod för att logga in på enkäten:',
      alt: 'Haka-inloggning'
    },
    tulokset: {
      header: 'Enkätens resultat',
      results1: 'Personalen vid yrkeshögskolorna kommer åt enkätens resultat i realtid via Arvo-tjänsten.',
      results2: 'Statistiska rapporter av enkätens resultat finns till allmänt påseende via ',
      results_link: 'utbildningsförvaltningens statistiktjänst Vipunen.'
    }
  },
  footer: {
    header: 'I samarbete med:',
    arene: {
      description: 'Rådet för yrkeshögskolornas rektorer, Arene',
      logo_alt: 'Arene logo'
    },
    okm: {
      description: 'Undervisnings- och kulturministeriet',
      logo_alt: 'Undervisnings- och kulturministeriet logo'
    },
    csc: {
      description: 'Arvo - informationstjänst för effektivitetshantering i utbildningsförvaltning',
      logo_alt: 'CSC logo'
    }
  },
  profiledata: {
    header: 'Bakgrundsinformation till enkäten',
    about: 'Som bakgrundsinformation för enkäten används följande uppgifter: utbildning, yrkeshögskola, yrkeshögskolans kommun, inledningsdatum för studierna, språket för examen och undervisningsform. Personuppgifter förmedlas inte vidare till enkäten och alla svar behandlas anonymt.',
    about_yamk: 'Som bakgrundinformation för enkäten används följande uppgifter: utbildning, yrkeshögskola, yrkeshögskolans kommun, inledningsdatum för studierna och språket för examen. Personuppgifter förmedlas inte vidare till enkäten och alla svar behandlas anonymt.',
    privacy_notice: {
      text: 'Dataskyddsbeskrivningen finns här <1>https://wiki.eduuni.fi/x/rUDNC</1>',
      link: 'https://wiki.eduuni.fi/x/rUDNC'
    },
    education: 'Examensbenämning',
    school: 'Yrkeshögskola',
    municipality: 'Kommun',
    startYear: 'Inledningsdatum för studierna',
    language: 'Språket för examen',
    funding: 'Finansieringskälla',
    form_of_education: 'Undervisningsform',
    reminder: 'Var god och kontrollera att uppgifterna om din utbildning är korrekta. Genom att gå vidare till enkäten godkänner du att uppgifterna används som bakgrundsinformation för enkäten.',
    survey: 'Gå till enkäten',
    type: {
      day: 'Dagundervisning',
      multi: 'Flerformsundervisning'
    },
    submit: 'Gå till enkäten'
  },
  errors: {
    title: 'Felsituation',
    general_error: 'Tyvärr uppstod ett ospecificerat fel. Vänligen försök igen senare och om felet återkommer, kontakta arvo@csc.fi',
    haka_error: 'Tyvärr lämnade Haka inte den uppgifter som krävs när du loggade in. Kontakta din egen läroanstalt.',
    virta_error: 'Där var ett fel när din  information hämtades från VIRTA högskoleregister. Om situationen upprepar sig, kontakta din egen läroanstalt.',
    no_data_in_virta: 'Tyvärr stämmer dina inloggningsuppgifter inte med informationen som finns i studieinformationstjänsten VIRTA. Kontakta din egen läroanstalt.',
    arvo_error: 'Tyvärr enkäten kunde inte öppnas. Vänligen försök igen senare och om felet återkommer, kontakta arvo@csc.fi',
    attach_code: 'Bifoga denna felkod i meddelandet'
  },
  opiskeluoikeus_errors: {
    invalid_organization: 'Din studierätt tillhör inte den högskola som din HAKA-inloggning ger till känna.',
    invalid_type: 'Studierättens typ är inkorrekt i studieinformationstjänsten VIRTA. Kontakta din egen läroanstalt.',
    invalid_date: 'Enligt den information som hämtas från studieinformationstjänsten VIRTA är din studierätt inte giltig. Kontakta din egen läroanstalt.',
    invalid_laajuus: 'Ofullständig information i studieinformationstjänsten VIRTA. Omfattningen av studierätten måste vara mer än 0 sp. Kontakta din egen läroanstalt.',
    not_enough_opintosuoritus: 'Du har inte tillräckligt med studiepoäng i studieinformationstjänsten VIRTA. Kontakta din egen läroanstalt.',
    no_kandi: 'Du saknar registrering om avlagd kandidatexamen i studieregistret.',
    header: 'Inga studierätter hittades.',
    no_rights_contact_study_office: 'Inga studierätter hittades med dina uppgifter från studieinformationstjänsten VIRTA. Kontakta din egen läroanstalt.',
    some_rights_contact_study_office: 'Med dina uppgifter hittades {{rights_count}} studierätter i studieinformationstjänsten VIRTA, men informationen räcker inte för att ge respons. Kontakta din egen läroanstalt.',
    additional_invalid_rights: 'Dessutom hittades {{rights_count}} studierätter, men informationen räcker inte för att ge respons. Kontakta din egen läroanstalt.'
  },
  images: {
    logo_alt: "AVOP logo"
  }
};

export default localizations_sv;
