import Localizedimage from '../common/localizedimage/localizedimage';
import './footer.scss';
import {useTranslation} from 'react-i18next';
import {useEffect, useState} from "react";

let Footer = () => {
  const {t} = useTranslation();
  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 500px)").matches
  )

  useEffect(() => {
    window
      .matchMedia("(min-width: 500px)")
      .addEventListener('change', e => setMatches( e.matches ));
  }, []);

  return (
    <footer className="container">
      <div className="footer-row">
        <div className="u-full-width">
          <h2>{t('footer.header')}</h2>
        </div>
      </div>
      {matches && (
        <>
          <div className="footer-row">
            <div className="one-third column footer-col-img arene">
              <Localizedimage image="arene" alt="footer.arene.logo_alt"/>
            </div>

            <div className="one-third column footer-col-img okm">
              <Localizedimage image="OKM_logo" alt="footer.okm.logo_alt"/>
            </div>

            <div className="one-third column footer-col-img csc">
              <Localizedimage image="CSC" alt="footer.csc.logo_alt"/>
            </div>
          </div>

          <div className="footer-row">
            <div className="one-third column footer-col-txt">
              <p>{t('footer.arene.description')}</p>
            </div>
            <div className="one-third column footer-col-txt">
              <p>{t('footer.okm.description')}</p>
            </div>
            <div className="one-third column footer-col-txt">
              <p>{t('footer.csc.description')}</p>
              <p>arvo@csc.fi</p>
            </div>
          </div>
        </>
      )}
      {!matches && (
        <>
          <Localizedimage image="arene" alt="footer.arene.logo_alt"/>
          <p>{t('footer.arene.description')}</p>
          <Localizedimage image="OKM_logo" alt="footer.okm.logo_alt"/>
          <p>{t('footer.okm.description')}</p>
          <Localizedimage image="CSC" alt="footer.csc.logo_alt"/>
          <p>{t('footer.csc.description')}</p>
          <p>arvo@csc.fi</p>
        </>
      )}
    </footer>);
}
export default Footer;
