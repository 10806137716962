import config from '../../config/base';
import Localizedimage from '../common/localizedimage/localizedimage';

import './content.scss';

import hakaLoginImage from '../../images/Haka_login_vaaka_lg.jpg';
import {Trans, useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';

function extractCurrentDomain() {
  return window.location.protocol + '//' +
    window.location.hostname +
    ((window.location.port === '') ? '' : ':' + window.location.port);
}

function Content() {
  const {t} = useTranslation();
  const {lang} = useParams();

  return (
    <div>
      <section id="theme">
        <div className="container">
          <div className="row">
            <div className="six columns">
              <div className="u-full-width">
                <div id="logo">
                  <Localizedimage image="logo" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <section id="heading">
          <div className="container">
            <div className="row">
              <div className="u-full-width">
                <h1>{t('content.title.title')}</h1>
              </div>
            </div>
          </div>
        </section>

        <section id="login" aria-label={t('content.login.alt')}>
          <div className="container">
            <div className="row">

              <div id="haka-login">
                <h2>{t('content.login.header')}</h2>
                <p id="login-description">{t('content.login.description')}</p>
              </div>

              <div id="haka" className="centered">
                <a aria-describedby="login-description" href={config.hakaLoginUrl(extractCurrentDomain(), lang)}>
                  <img src={hakaLoginImage} alt={t('content.login.alt')} tabIndex="0"/>
                </a>
              </div>

            </div>
          </div>
        </section>

        <section id="main_content">
          <div className="container">
            <div className="row">
              <div className="u-full-width">
                <h2>{t('content.kysely.information')}</h2>
              </div>
              <div className="u-full-width">
                <p>{t('content.kysely.about1')}</p>
              </div>
              <div className="u-full-width">
                <p>{t('content.kysely.about2')}</p>
              </div>
              <div className="u-full-width">
                <p>{t('content.kysely.about3')}</p>
                <p>
                  <Trans i18nKey="content.kysely.privacy.text">
                    Lisätietoa:
                    <a href={t('content.kysely.privacy.privacyLink')} target="_blank" rel="noreferrer">link1</a>
                    ja
                    <a href={t('content.kysely.privacy.accessibilityLink')} target="_blank" rel="noreferrer">link2</a>
                  </Trans>
                </p>
              </div>
              <div className="u-full-width">
                <h2>{t('content.tulokset.header')}</h2>
              </div>
              <div className="u-full-width">
                <p>{t('content.tulokset.results1')}</p>
              </div>
              <div className="u-full-width">
                <p>
                  <span>{t('content.tulokset.results2')}</span>
                  <a className="external" href="https://vipunen.fi/" target="_blank" rel="noreferrer"><span>{t('content.tulokset.results_link')}</span></a>
                </p>
              </div>
            </div>
          </div>
        </section>
      </section>
    </div>
  );
}

export default Content;
