import {useEffect} from 'react';
import Header from './header/Header';
import Footer from './footer/Footer';

import {Route, Routes, useParams} from "react-router-dom";
import Home from "./home/Home";
import Userprofile from "./user/userprofile";
import Error from "./Error";

import 'react-skeleton-css/styles/skeleton.2.0.4.css';
import 'react-skeleton-css/styles/normalize.3.0.2.css';
import './main.scss';
import {useTranslation} from 'react-i18next';

function setLocale(lang, i18n) {
  if (lang) {
    if (lang.indexOf('en') === 0 ) {
      i18n.changeLanguage('en')
    } else if (lang.indexOf('sv') === 0 ) {
      i18n.changeLanguage('sv')
    } else {
      i18n.changeLanguage('fi')
    }
  }
}

function AppComponent() {

  const { t, i18n } = useTranslation();
  const {lang} = useParams();
  useEffect(() => setLocale(lang, i18n), [lang, i18n]);
  useEffect(() => { document.title = t('content.title.title'); }, [lang, t]);
  useEffect(() => { document.documentElement.setAttribute("lang", lang) }, [lang]);

  return (
    <div>
      <Header />
      <main>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/user" element={<Userprofile />}/>
          <Route path="/error/:status" element={<Error />}/>
          <Route path="/error/:status/:sessionid" element={<Error />}/>
          <Route path="*" element={<Error />}/>
        </Routes>
      </main>
      <Footer/>
    </div>
  );
}

export default AppComponent;

