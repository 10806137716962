import Localeswitcher from './../common/localeswitcher/localeswitcher';
import './header.scss';


export default function Header() {

  return (
    <header>
      <div className="container">
        <div className="row">
          <div className="u-full-width">
            <Localeswitcher />
          </div>
        </div>
      </div>
    </header>
  );
}
