import OKM_logo_fi from '../../../images/OKM_logo-fi.png';
import logo_fi from '../../../images/logo-fi.png';
import CSC_fi from '../../../images/csc_logo-fi.png';
import vipunen_fi from '../../../images/vipunen-fi.png';

import OKM_logo_sv from '../../../images/OKM_logo-sv.png';
import logo_sv from '../../../images/logo-sv.png';
import vipunen_sv from '../../../images/vipunen-sv.png';

import OKM_logo_en from '../../../images/OKM_logo-en.png';
import logo_en from '../../../images/logo-en.png';
import CSC_en from '../../../images/csc_logo-en.png';
import vipunen_en from '../../../images/vipunen-en.png';

import arene from '../../../images/arene.png';

const localisedImagesUrls = {
  fi: {
    OKM_logo: OKM_logo_fi,
    logo: logo_fi,
    CSC: CSC_fi,
    vipunen: vipunen_fi,
    arene: arene,
  },
  sv: {
    OKM_logo: OKM_logo_sv,
    logo: logo_sv,
    CSC: CSC_en,
    vipunen: vipunen_sv,
    arene: arene,
  },
  en: {
    OKM_logo: OKM_logo_en,
    logo: logo_en,
    CSC: CSC_en,
    vipunen: vipunen_en,
    arene: arene,
  }
};

export default localisedImagesUrls;
