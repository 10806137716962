let localizations_en = {
  content: {
    title: {
      title: 'Feedback questionnaire for University of Applied Sciences graduands (AVOP)'
    },
    kysely: {
      information: 'About the questionnaire',
      about1: 'AVOP feedback questionnaire asks graduating students to evaluate and provide feedback on their education. The findings are used locally by institutions to improve their institutional processes and practices and nationally to inform performance-based monitoring and funding. All graduating degree students from universities of applied sciences are requested to fill out the questionnaire.',
      about2: 'Collected personal data is used to fetch information related to person’s study right from VIRTA - higher education achievement register as background information to create a respondent ID and enable the use of electronic graduate feedback questionnaire. The collected personal data is not transferred to the AVOP-survey and your answers to the survey are handled anonymously.',
      about3: 'The questionnaire was developed in close cooperation with the Rectors\' Conference of Finnish Universities of Applied Sciences (ARENE), the Union of Students in Finnish Universities of Applied Sciences (SAMOK), Foundation for Research on Studying and Education (OTUS) and the Ministry for Education and Culture. The responsibility for development and implementation of the questionnaire lies with Arene.',
      privacy: {
        text: 'More information in <1>Privacy policy</1> and <3>Accessibility statement</3>.',
        privacyLink: 'https://wiki.eduuni.fi/pages/viewpage.action?pageId=147669165',
        accessibilityLink: 'https://wiki.eduuni.fi/display/CscArvo/Arvo+Accessibility+Statement',
      }
    },
    login: {
      header: 'UAS student',
      description: 'Please sign in to the survey by using your own UAS credentials:',
      alt: 'Haka login'
    },
    tulokset: {
      header: 'The results',
      results1: 'The staff of the universities of applied sciences can review the real time survey results by using the Arvo - education management information service with appropriate user rights.',
      results2: 'The statistics are freely available at the ',
      results_link: 'Vipunen statistics services of the educational administration.'
    }
  },
  footer: {
    header: 'In cooperation:',
    arene: {
      description: 'Rectors’ Conference of Finnish Universities of Applied Sciences, Arene',
      logo_alt: 'Arene logo'
    },
    okm: {
      description: 'Ministry of Education and Culture',
      logo_alt: 'Ministry of education and culture logo'
    },
    csc: {
      description: 'Arvo - education management information service',
      logo_alt: 'CSC logo'
    }
  },
  profiledata: {
    header: 'Background information',
    about: 'Information on education, university of applied sciences, municipality, start date of the right to study, language of learning and mode of education is used as survey background information. The collected personal information is not transferred to the feedback system.',
    about_yamk: 'Information on education, university of applied sciences, municipality, start date of the right to study and language of learning is used as survey background information. The collected personal information is not transferred to the feedback system.',
    privacy_notice: {
      text: 'The privacy notice of the survey can be found here <1>https://wiki.eduuni.fi/x/rUDNC</1>',
      link: 'https://wiki.eduuni.fi/x/rUDNC'
    },
    education: 'Degree title',
    school: 'University of applied sciences',
    municipality: 'Municipality',
    startYear: 'Start date of the right to study',
    language: 'Language of learning',
    funding: 'Funding source',
    form_of_education: 'Mode of education',
    reminder: 'Please confirm the education information is correct. Once you move to the feedback questionnaire, you accept that the information is used as background information.',
    survey: 'Go to the survey',
    type: {
      day: 'Daytime studies',
      multi: 'Multiform studies'
    },
    submit: 'Go to the survey'
  },
  errors: {
    title: 'Error situation',
    general_error: 'Unfortunately an unspecified error occurred. Please try again later and if the error reoccurs, contact arvo@csc.fi',
    haka_error: 'Unfortunately Haka did not provide the required information when you logged in. Contact your own educational institution.',
    virta_error: 'There was an error when retrieving your information from the VIRTA higher education achievement register. If this  situation happens again, contact your own educational institution.',
    no_data_in_virta: 'Unfortunately your login information does not match the information found in the VIRTA higher education achievement register. Contact your own educational institution.',
    arvo_error: 'Unfortunately an error occurred when trying to access the questionnaire. Please try again later and if the error reoccurs, contact arvo@csc.fi',
    attach_code: 'Attach this error code to the message'
  },
  opiskeluoikeus_errors: {
    invalid_organization: 'Your right to study does not correspond to the HAKA home organisation.',
    invalid_type: 'The type of the right to study is incorrect in the VIRTA higher education achievement register. Contact your own educational institution.',
    invalid_date: 'According to the information retrieved from the VIRTA higher education achievement register, your right to study is not valid. Contact your own educational institution.',
    invalid_laajuus: 'Incomplete information in the VIRTA higher education achievement register. The scope of the right to study must be more than 0 credits. Contact your own educational institution.',
    not_enough_opintosuoritus: 'You don\'t have enough credits in the VIRTA higher education achievement register. Contact your own educational institution.',
    no_kandi: 'Sinulla ole kandidaatin tutkinnon suoritusmerkintää opintorekisterissä.',
    header: 'Rights to study weren\'t found.',
    no_rights_contact_study_office: 'No rights to study were found with your information from the VIRTA higher education achievement register. Contact your own educational institution.',
    some_rights_contact_study_office: 'With your information, {{rights_count}} study rights were found in the VIRTA higher education achievent register, but the information is not sufficient for giving feedback. Contact your own educational institution.',
    additional_invalid_rights: 'In addition, {{rights_count}} rights to study were found without the required information to grant access to the graduate survey. Contact your own educational institution.'
  },
  images: {
    logo_alt: 'AVOP logo'
  }
};

export default localizations_en;
