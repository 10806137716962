let localizations_fi = {
  content: {
    title: {
      title: 'Ammattikorkeakoulujen valmistumisvaiheen opiskelijapalautekysely (AVOP)'
    },
    kysely: {
      information: 'Tietoa kyselystä',
      about1: 'AVOP on palautekysely, jossa opiskelija arvioi ja antaa palautetta toteutuneesta koulutuksesta. Kyselyn tuloksia hyödynnetään sekä paikallisesti korkeakoulun oman toiminnan kehittämisessä että valtakunnallisesti koulutuksen ohjauksessa ja rahoituksessa. Kyselyn täyttävät kaikki ammattikorkeakouluista valmistuvat tutkinto-opiskelijat.',
      about2: 'Opiskelijan HAKA-kirjautumisen avulla haetaan henkilön opiskeluoikeuteen liittyviä tietoja VIRTA-opintotietopalvelusta taustatiedoksi AVOP-kyselyn vastaajatunnuksen luomiseksi ja sähköisen palautteen antamisen mahdollistamiseksi. Henkilön yksilöiviä tunnisteita ei välitetä eteenpäin itse AVOP-kyselyyn ja annettua palautetta käsitellään anonyymisti.',
      about3: 'AVOP-kyselymittaristo on syntynyt Ammattikorkeakoulujen rehtorineuvosto Arene ry:n, Suomen opiskelijakuntien SAMOK ry:n, Opiskelun ja koulutuksen tutkimussäätiön (OTUS) ja opetus- ja kulttuuriministeriön (OKM) yhteisenä hankkeena. Kyselymittariston käyttöönotosta ja sen kehittämisestä vastaa Arene ry.',
      privacy: {
        text: 'Lisätietoa: <1>tietosuojailmoitus</1> ja <3>saavutettavuusseloste</3>.',
        privacyLink: 'https://wiki.eduuni.fi/pages/viewpage.action?pageId=147669165',
        accessibilityLink: 'https://wiki.eduuni.fi/display/CscArvo/Arvo+saavutettavuusseloste'
      }
    },
    login: {
      header: 'Ammattikorkeakouluopiskelija',
      description: 'Vastaa kyselyyn kirjautumalla oman korkeakoulusi tunnuksilla:',
      alt: 'Haka-kirjautuminen'
    },
    tulokset: {
      header: 'Kyselyn tulokset',
      results1: 'Ammattikorkeakoulujen henkilökunta voi tarkastella kyselyn tuloksia reaaliaikaisesti Arvo - opetushallinnon vaikuttavuustietopalvelun avulla. Katseluoikeudet (Arvo-katselija) voi anoa Opintopolun virkailijantyöpöydän kautta (Omat tiedot)',
      results2: 'Kyselyn tuloksista johdettuja tilastoraportteja on avoimesti saatavilla ',
      results_link: 'opetushallinnon tilastopalvelu Vipusessa.'
    }
  },
  footer: {
    header: 'Yhteistyössä:',
    arene: {
      description: 'Ammattikorkeakoulujen rehtorineuvosto Arene ry',
      logo_alt: 'Arenen logo'
    },
    okm: {
      description: 'Opetus- ja kulttuuriministeriö',
      logo_alt: 'Opetus- ja kulttuuriministeriön logo'
    },
    csc: {
      description: 'Arvo - opetushallinnon vaikuttavuustietopalvelu',
      logo_alt: 'CSC - Tieteen tietotekniikan keskuksen logo'
    }
  },
  profiledata: {
    header: 'Palautekyselyn taustatiedot',
    about: 'Palautekyselyyn välitetään taustatiedoiksi koulutus, ammattikorkeakoulu, koulutuksen kunta, tutkinnon aloitusvuosi, tutkinnon suorituskieli ja koulutusmuoto. Henkilöön liittyviä tietoja ei välitetä palautekyselylle.',
    about_yamk: 'Palautekyselyyn välitetään taustatiedoiksi koulutus, ammattikorkeakoulu, koulutuksen kunta, tutkinnon aloitusvuosi ja tutkinnon suorituskieli. Henkilöön liittyviä tietoja ei välitetä palautekyselylle.',
    privacy_notice: {
      text: 'Kyselyn tietosuojaseloste löytyy täältä <1>https://wiki.eduuni.fi/x/rUDNC</1>',
      link: 'https://wiki.eduuni.fi/x/rUDNC'
    },
    education: 'Tutkintonimike',
    school: 'Ammattikorkeakoulu',
    municipality: 'Kunta',
    startYear: 'Tutkinnon aloitusvuosi',
    language: 'Tutkinnon suorituskieli',
    funding: 'Rahoituslähde',
    form_of_education: 'Koulutusmuoto',
    reminder: 'Tarkistathan, että koulutuksesi tiedot ovat oikein. Siirtymällä palautekyselyyn hyväksyt tietojen käyttämisen palautekyselyn taustatietona.',
    survey: 'Siirry palautekyselyyn',
    type: {
      day: 'Päiväopiskelu',
      multi: 'Monimuoto-opiskelu'
    },
    submit: 'Siirry palautekyselyyn'
  },
  errors: {
    title: 'Virhetilanne',
    general_error: 'Valitettavasti järjestelmän toiminnassa tapahtui virhe. Jos ongelma toistuu, ole hyvä ja ota yhteyttä ylläpitoon arvo@csc.fi',
    haka_error: 'Valitettavasti Haka ei luovuttanut vaadittuja tietoja kirjautumisesi yhteydessä. Ota yhteyttä omaan oppilaitokseesi.',
    virta_error: 'Virhe haettaessa tietojasi VIRTA-opintotietopalvelusta. Jos tilanne toistuu, ota yhteyttä omaan oppilaitokseesi.',
    no_data_in_virta: 'Valitettavasti kirjautumistietosi eivät vastaa VIRTA-opintotietopalvelusta löydettäviä tietoja. Ota yhteyttä omaan oppilaitokseesi.',
    arvo_error: 'Valitettavasti kyselyyn siirtymisessä tapahtui virhe. Jos ongelma toistuu, ole hyvä ja ota yhteyttä ylläpitoon arvo@csc.fi',
    attach_code: 'Liitä viestiin tämä virhekoodi'
  },
  opiskeluoikeus_errors: {
    invalid_organization: 'Opiskeluoikeutesi ei kuulu HAKA-kirjaumista vastaavaan korkeakouluun.',
    invalid_type: 'Väärä opiskeluoikeuden tyyppi VIRTA-opintotietopalvelussa. Ota yhteyttä omaan oppilaitokseesi.',
    invalid_date: 'VIRTA-opintotietopalvelusta haettujen tietojen mukaan opiskeluoikeutesi ei ole voimassa. Ota yhteyttä omaan oppilaitokseesi.',
    invalid_laajuus: 'Puutteellinen tieto VIRTA-opintotietopalvelussa. Opiskeluoikeuden laajuuden pitää olla yli 0 op. Ota yhteyttä omaan oppilaitokseesi.',
    not_enough_opintosuoritus: 'Sinulla ei ole riittävästi opintopisteitä VIRTA-opintotietopalvelussa. Ota yhteyttä omaan oppilaitokseesi.',
    no_kandi: 'Sinulla ole kandidaatin tutkinnon suoritusmerkintää opintorekisterissä.',
    header: 'Opiskeluoikeuksia ei löydy',
    no_rights_contact_study_office: 'Tiedoillasi ei löytynyt yhtään opiskeluoikeutta VIRTA-opintotietopalvelusta. Ota yhteyttä omaan oppilaitokseesi.',
    some_rights_contact_study_office: 'Tiedoillasi löytyi {{rights_count}} opiskeluoikeutta VIRTA-opintotietopalvelusta, mutta tiedot eivät riitä palautteen antamiseen. Ota yhteyttä omaan oppilaitokseesi.',
    additional_invalid_rights: 'Lisäksi löytyi {{rights_count}} oikeutta, mutta tiedot eivät riitä palautteen antamiseen. Ota yhteyttä omaan oppilaitokseesi.'
  },
};

export default localizations_fi;
